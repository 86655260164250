<template>
  <v-card tile elevation="0" outlined>
    <v-card-text>Unternehmen</v-card-text>
    <v-card-title class="mt-n8">{{ company }}</v-card-title>
    <v-card-text>
      Willkommen in Ihrem Dashboard.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["userContext"]),

    company: function()  {
      return this.userContext?.customer?.defaultBillingAddress?.company;
    }
  }
}
</script>