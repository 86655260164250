import ApiService from "@/services/api.service";

const EmployeeService = {
    list: async function(requestBody) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/employees`, requestBody);

            console.log("Employee List Response", response);
            return response.data;
        } catch (error) {
            console.log("Employee List Error", error);
        }
    },

    register: async function(requestBody) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/employee/register`, requestBody);

            console.log("Employee Register Response", response);

            return response.data;
        } catch (error) {
            console.log("Employee Register Error", error);
        }
    },

    updateProfile: async function(requestBody) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/employee/change-profile`, requestBody);

            console.log("Employee Change Profile Response", response);

            return response.data;
        } catch (error) {
            console.log("Employee Change Profile Error", error);
        }
    },

    activate: async function(requestBody) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/employee/activate`, requestBody);

            console.log("Employee Activate Response", response);

            return response.data;
        } catch (error) {
            console.log("Employee Activate Error", error);
        }
    },

    deactivate: async function(requestBody) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/employee/deactivate`, requestBody);

            console.log("Employee Deactivate Response", response);

            return response.data;
        } catch (error) {
            console.log("Employee Deactivate Error", error);
        }
    }
}

export default EmployeeService;