import OrderService from "@/modules/order/services/order.service";

const state = {
    loading: false,
    orders: null,
}

const getters = {
    orders: (state) => state.orders,
}

const actions = {
    async list({ commit }, requestBody) {
        commit("REQUESTING_ORDERS");

        try {
            const orders = await OrderService.list(requestBody);

            console.log("GET_ORDERS_LIST_SUCCESS", orders)

            commit("GET_ORDERS_LIST_SUCCESS", orders);

            return true;
        } catch (error) {
            console.log("GET_ORDERS_LIST_FAILED", error);

            commit("GET_ORDERS_LIST_FAILED");

            return false;
        }
    },
}

const mutations = {
    REQUESTING_ORDERS(state) {
        state.loading = true;
    },

    GET_ORDERS_LIST_SUCCESS(state, orders) {
        state.loading = false;
        state.orders = orders;
    },

    GET_ORDERS_LIST_FAILED(state) {
        state.loading = false;
    },
}

export const order = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}