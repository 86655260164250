<template>
  <v-list-item two-line dark dense>
    <v-list-item-avatar color="primary darken-1 mb-3 mt-3">
      <v-icon>mdi-account</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ username }}</v-list-item-title>
      <v-list-item-subtitle>{{ company }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "user",

  computed: {
    ...mapGetters("auth", ["userContext"]),

    username: function() {
      return `${this.userContext?.customer?.firstName} ${this.userContext?.customer?.lastName}`;
    },

    company: function() {
      return this.userContext?.customer?.company;
    }
  }
}
</script>