import Vue from 'vue'
import Vuex from 'vuex'

// Import modules
import { auth } from "@/store/modules/auth.module";
import { employee } from "@/modules/employee/store/modules/employee.module";
import { product } from "@/modules/shop/store/modules/product.module";
import { order } from "@/modules/order/store/modules/order.module";
import { benefit } from "@/modules/benefit/store/modules/benefit.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    employee,
    product,
    order,
    benefit
  }
})
