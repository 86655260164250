<template>
  <v-snackbar color="#303a4f" v-model="updateExists" :timeout="-1" top>
    <h3 class="text-center font-weight-light">{{ text }}</h3>
    <v-divider class="mb-2 mt-2"></v-divider>
    <v-btn color="primary" block elevation="0" @click="refreshApp()">
      Update
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    refreshing: false,
    registration: null,
    updateExists: false,
    text: "Neue Version der App verfügbar!",
  }),
  created() {
    document.addEventListener("appUpdated", this.showRefreshUI, true);
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>