import axios from "axios";
import StorageService from "@/services/storage.service";

const ApiService = {
    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },

    setAdapter() {
        axios.defaults.adapter = require("axios/lib/adapters/http");
    },

    setHeader(swContextToken = StorageService.get("context-token"), swAccessKey = process.env.VUE_APP_SW_ACCESS_KEY) {
        axios.defaults.headers["sw-context-token"] = swContextToken ? swContextToken : '';
        axios.defaults.headers["sw-access-key"] = swAccessKey;
        axios.defaults.headers["Content-Type"] = "application/json";
    },

    removeHeader() {
        axios.defaults.headers = {}
    },

    get(resource) {
      return axios.get(resource);
    },

    post(resource, requestBody) {
        return axios.post(resource, requestBody);
    },

    put(resource, data) {
        return axios.put(resource, data);
    },

    patch(resource, data) {
        return axios.patch(resource, data);
    },

    delete(resource, data) {
        return axios.delete(resource, data);
    }
}

export default ApiService;