<template>
  <v-card tile elevation="10" color="primary" dark>
    <v-card-text class="text-center pt-10">
      <v-avatar color="primary">
        <v-icon x-large>mdi-account</v-icon>
      </v-avatar>
    </v-card-text>
    <v-card-text class="text-center mt-n7 pb-10">Mitarbeiter</v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn color="primary" block tile elevation="0" to="/Employee">verwalten</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

}
</script>