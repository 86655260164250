import { AuthError, AuthService } from "@/services/auth.service";
import StorageService from "@/services/storage.service";

const state = {
    loading: false,
    auth: null,
    userContext: null,
    contextToken: StorageService.get("context-token") ? StorageService.get("context-token") : null,
    error: null,
}

const getters = {
    loading: (state) => state.loading,
    auth: (state) => state.auth,
    userContext: (state) => state.userContext,
    contextToken: (state) => state.contextToken,
    error: (state) => state.error,
}

const actions = {
    async login({ commit }, credentials) {
        commit("REQUESTING_LOGIN");

        try {
            const auth = await AuthService.login(credentials);

            commit("LOGIN_SUCCESS", auth);

            return true;
        } catch (error) {
            console.log("Login Error", error);

            if (error instanceof AuthError) {
                commit("LOGIN_FAILED", {
                    status: error.status,
                    title: error.title,
                    message: error.message,
                });

                return false;
            }

            commit("LOGIN_FAILED");

            return false;
        }
    },

    async logout({ commit }) {
        commit("REQUESTING_LOGIN");

        try {
            const auth = await AuthService.logout();

            commit("LOGOUT_SUCCESS", auth);

            return true;
        } catch (error) {
            console.log("Logout Error", error);

            commit("LOGOUT_FAILED");

            return false;
        }
    },

    async getUserContext({ commit }) {
        try {
            const userContext = await AuthService.getUserContext();

            commit("GET_USER_CONTEXT_SUCCESS", userContext);

            return true;
        } catch (error) {
            console.log("User Context Error", error);

            commit("GET_USER_CONTEXT_FAILED");

            return false;
        }
    }
}

const mutations = {
    REQUESTING_LOGIN(state) {
        state.error = null;
        state.loading = true;
    },

    LOGIN_SUCCESS(state, auth) {
        state.loading = false;
        state.auth = auth;
        state.contextToken = StorageService.get("context-token") ? StorageService.get("context-token") : null
    },

    LOGIN_FAILED(state, { status = 999, title = null, message = null }) {
        state.loading = false;

        state.error = { status, title, message };
    },

    LOGOUT_SUCCESS(state) {
        state.loading = false;
        state.auth = null;
        state.userContext = null;
        state.contextToken = StorageService.get("context-token") ? StorageService.get("context-token") : null
    },

    LOGOUT_FAILED(state) {
        state.loading = false;
    },

    GET_USER_CONTEXT_SUCCESS(state, userContext) {
        state.loading = false;
        state.userContext = userContext;

        if (!userContext.customer) {
            StorageService.set("context-token", "");
            state.contextToken = StorageService.get("context-token") ? StorageService.get("context-token") : null
        }
    },

    GET_USER_CONTEXT_FAILED(state) {
        state.loading = false;
        state.contextToken = StorageService.get("context-token") ? StorageService.get("context-token") : null
    }
}

export const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
