import ProductService from "@/modules/shop/services/product.service";

const state = {
    loading: false,
    products: null,
}

const getters = {
    products: (state) => state.products,
}

const actions = {
    async list({ commit }, requestBody) {
        commit("REQUESTING_PRODUCTS");

        try {
            const products = await ProductService.list(requestBody);

            console.log("GET_PRODUCT_LIST_SUCCESS", products)

            commit("GET_PRODUCT_LIST_SUCCESS", products);

            return true;
        } catch (error) {
            console.log("GET_PRODUCT_LIST_FAILED", error);

            commit("GET_PRODUCT_LIST_FAILED");

            return false;
        }
    },

    async setSalesChannel({ commit }, productId = null) {
        commit("REQUESTING_PRODUCTS");

        try {
            const products = await ProductService.setSalesChannel(productId);

            console.log("SET_SALES_CHANNEL_SUCCESS", products)

            commit("SET_SALES_CHANNEL_SUCCESS", products);

            return true;
        } catch (error) {
            console.log("SET_SALES_CHANNEL_FAILED", error);

            commit("SET_SALES_CHANNEL_FAILED");

            return false;
        }
    },
}

const mutations = {
    REQUESTING_PRODUCTS(state) {
        state.loading = true;
    },

    GET_PRODUCT_LIST_SUCCESS(state, products) {
        state.loading = false;
        state.products = products;
    },

    GET_PRODUCT_LIST_FAILED(state) {
        state.loading = false;
    },

    SET_SALES_CHANNEL_SUCCESS(state) {
        console.log("success", state);
    },

    SET_SALES_CHANNEL_FAILED(state) {
        console.log("error", state);
    }
}

export const product = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}