import OrderService from "@/modules/order/services/order.service";

const state = {
    loading: false,
    orderLineItems: null,
}

const getters = {
    orderLineItems: (state) => state.orderLineItems,
}

const actions = {
    async list({ commit }, requestBody) {
        commit("REQUESTING_ORDERS");

        try {
            const orders = await OrderService.list(requestBody);

            console.log("GET_ORDERS_LIST_SUCCESS", orders)

            commit("GET_ORDERS_LIST_SUCCESS", orders);

            return true;
        } catch (error) {
            console.log("GET_ORDERS_LIST_FAILED", error);

            commit("GET_ORDERS_LIST_FAILED");

            return false;
        }
    },
}

const mutations = {
    REQUESTING_ORDERS(state) {
        state.loading = true;
    },

    GET_ORDERS_LIST_SUCCESS(state, orders) {
        state.loading = false;

        let lineItems = [];
        orders.elements.forEach((item) => {
            item.lineItems.forEach((lineItem) => {
                if (!lineItem.extensions.scans.length) {
                    return;
                }

                lineItem.extensions.scans.forEach((scanItem) => {
                    lineItems.push(
                        {
                            orderNumber: item.orderNumber,
                            orderDate: item.orderDate,
                            orderCustomerName: item.orderCustomer.firstName + " " + item.orderCustomer.lastName,
                            lineItemLabel: lineItem.label,
                            lineItemUnitPrice: lineItem.unitPrice,
                            scanned: scanItem.createdAt
                        }
                    )
                })

            })
        })
        state.orderLineItems = lineItems;
    },

    GET_ORDERS_LIST_FAILED(state) {
        state.loading = false;
    },
}

export const benefit = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}