<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card tile elevation="0">
          <v-card-text>Ihr Dashboard</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <CompanyDashboardWidget />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3">
        <EmployeeDashboardWidget />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3" v-if="false">
        <OrderDashboardWidget />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3" v-if="false">
        <BenefitDashboardWidget />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyDashboardWidget from "@/modules/company/components/dashboard/CompanyDashboardWidget";
import EmployeeDashboardWidget from "@/modules/employee/components/dashboard/EmployeeDashboardWidget";
import OrderDashboardWidget from "@/modules/order/components/dashboard/OrderDashboardWidget";
import BenefitDashboardWidget from "@/modules/benefit/components/dashboard/BenefitDashboardWidget";

export default {
  components: {
    CompanyDashboardWidget,
    EmployeeDashboardWidget,
    OrderDashboardWidget,
    BenefitDashboardWidget
  }
}
</script>