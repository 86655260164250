<template>
  <v-app-bar app flat color="#383e42" dark height="65px">
    <v-toolbar-title>{{ currentRouteName }} | {{ portalName }}</v-toolbar-title>
    <v-spacer />
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    portalName() {
      return process.env.VUE_APP_PORTAL_NAME
    }
  }
}
</script>