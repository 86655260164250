<template>
  <v-list dark color="primary">
    <v-list-item link to="/">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
<!--
    <v-list-item link to="/Product">
      <v-list-item-icon>
        <v-icon>mdi-cart</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Shop</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
-->

    <v-list-item link to="/Employee">
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Mitarbeiter</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link to="/Order" v-if="false">
      <v-list-item-icon>
        <v-icon>mdi-cart</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Bestellungen</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link to="/Benefit" v-if="false">
      <v-list-item-icon>
        <v-icon>mdi-ticket</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Benefit-Nutzung</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link to="/Company" v-if="false">
      <v-list-item-icon>
        <v-icon>mdi-domain</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Unternehmen</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  data: () => ({})
}
</script>
