import ApiService from "@/services/api.service";

const AddressService = {
    list: async function(requestBody = {}) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/account/list-address`, requestBody);

            console.log("Address List Response", response);
            return response.data;
        } catch (error) {
            console.log("Address List Error", error);
        }
    },
}

export default AddressService;