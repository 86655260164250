import EmployeeService from "@/modules/employee/services/employee.service";
import SalutationService from "@/modules/employee/services/salutation.service";
import AddressService from "@/modules/employee/services/address.service";

const state = {
    loading: false,
    employees: null,
    salutations: null,
    addresses: null
}

const getters = {
    loading: (state) => state.loading,
    employees: (state) => state.employees,
    salutations: (state) => state.salutations,
    addresses: (state) => state.addresses
}

const actions = {
    async list({ commit }, requestBody = {}) {
        commit("REQUESTING_EMPLOYEES");

        try {
            const employees = await EmployeeService.list(requestBody);

            console.log("GET_EMPLOYEES_LIST_SUCCESS", employees)

            commit("GET_EMPLOYEES_LIST_SUCCESS", employees);

            return true;
        } catch (error) {
            console.log("GET_EMPLOYEES_LIST_FAILED", error);

            commit("GET_EMPLOYEES_LIST_FAILED");

            return false;
        }
    },

    async getSalutationList({ commit }, requestBody = {}) {
        try {
            const salutations = await SalutationService.list(requestBody);

            console.log("GET_SALUTATION_LIST_SUCCESS", salutations)

            commit("GET_SALUTATION_LIST_SUCCESS", salutations);

            return true;
        } catch (error) {
            console.log("GET_SALUTATION_LIST_FAILED", error);

            commit("GET_SALUTATION_LIST_FAILED");

            return false;
        }
    },

    async getAddressList({ commit }, requestBody = {}) {
        try {
            const addresses = await AddressService.list(requestBody);

            console.log("GET_ADDRESS_LIST_SUCCESS", addresses)

            commit("GET_ADDRESS_LIST_SUCCESS", addresses);

            return true;
        } catch (error) {
            console.log("GET_ADDRESS_LIST_FAILED", error);

            commit("GET_ADDRESS_LIST_FAILED");

            return false;
        }
    }
}

const mutations = {
    REQUESTING_EMPLOYEES(state) {
        state.loading = true;
    },

    GET_EMPLOYEES_LIST_SUCCESS(state, employees) {
        state.loading = false;
        state.employees = employees;
    },

    GET_EMPLOYEES_LIST_FAILED(state) {
        state.loading = false;
    },

    GET_SALUTATION_LIST_SUCCESS(state, salutations) {
        state.salutations = salutations;
    },

    GET_SALUTATION_LIST_FAILED(state) {
        state.loading = false;
    },

    GET_ADDRESS_LIST_SUCCESS(state, addresses) {
        state.addresses = addresses;
    },

    GET_ADDRESS_LIST_FAILED(state) {
        state.loading = false;
    }
}

export const employee = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}