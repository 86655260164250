<template>
  <v-app>
    <v-progress-linear
        v-if="loading"
        color="primary"
        indeterminate
        height="6"
    ></v-progress-linear>

    <Login v-if="!contextToken" />

    <v-main v-else>
      <Sidebar />
      <Topbar />
      <router-view />
    </v-main>

    <UpdateBanner />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Login from "@/components/auth/Login";
import Sidebar from "@/components/layout/Sidebar";
import Topbar from "@/components/layout/Topbar";
import UpdateBanner from "@/components/app/UpdateBanner";
import ApiService from "@/services/api.service";

export default {
  name: 'App',

  components: {
    Topbar,
    Sidebar,
    Login,
    UpdateBanner,
  },

  data: () => ({
    //
  }),

  created() {
    if (!this.contextToken) {
      return false;
    }

    ApiService.setHeader(this.contextToken);
    this.getUserContext();
  },

  computed: {
    ...mapGetters("auth", ["loading", "auth", "contextToken"]),
  },

  methods: {
    ...mapActions("auth", ["getUserContext"]),
  }
};
</script>

