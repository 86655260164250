import ApiService from "@/services/api.service";

const ProductService = {
    list: async function(requestBody) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/product`, requestBody);

            console.log("Product List Response", response);
            return response.data;
        } catch (error) {
            console.log("Product List Error", error);
        }
    },

    setSalesChannel: async function(productId) {
        try {
            const response = await ApiService.patch(`${process.env.VUE_APP_REQUEST_URL}/product-manager/${productId}/set-sales-channel`);

            console.log("Set Sales Channel Response", response);
            return response.data;
        } catch (error) {
            console.log("Set Sales Channel Error", error);
        }
    }
}

export default ProductService;