import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/employee',
    name: 'Mitarbeiter',
    component: () => import(/* webpackChunkName: "employee" */ '@/modules/employee/views/Employee')
  },
  {
    path: '/product',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "product" */ '@/modules/shop/views/Product')
  },
  {
    path: '/order',
    name: 'Bestellungen',
    component: () => import(/* webpackChunkName: "order" */ '@/modules/order/views/Order')
  },
  {
    path: '/benefit',
    name: 'Benefits',
    component: () => import(/* webpackChunkName: "benefit" */ '@/modules/benefit/views/Benefit')
  },
  {
    path: '/company',
    name: 'Unternehmen',
    component: () => import(/* webpackChunkName: "company" */ '@/modules/company/views/Company')
  },
]

const router = new VueRouter({
  routes
})

export default router
