import ApiService from "@/services/api.service";

const SalutationService = {
    list: async function(requestBody = {}) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/salutation`, requestBody);

            console.log("Salutation List Response", response);
            return response.data;
        } catch (error) {
            console.log("Salutation List Error", error);
        }
    },
}

export default SalutationService;