import ApiService from "@/services/api.service";
import StorageService from "@/services/storage.service";

function AuthError(status, title, message) {
    this.status = status;
    this.title = title;
    this.message = message;
}

const AuthService = {
    login: async function(credentials) {
        try {
            ApiService.removeHeader();
            ApiService.setHeader();

            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/account/login`, credentials);

            StorageService.set("context-token", response.data.contextToken);
            ApiService.setHeader(response.data.contextToken);

            console.log("Login Response", response);
            return response;
        } catch (error) {
            console.log("Login Error", error);

            if (error.response) {
                throw new AuthError(
                    error.response.data.errors[0].status,
                    error.response.data.errors[0].title,
                    error.response.data.errors[0].detail
                );
            }
        }
    },

    logout: async function() {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/account/logout`);

            StorageService.set("context-token", "");
            StorageService.set("sw-access-key", "");
            ApiService.removeHeader();

            console.log("Logout Response", response);
            return response;
        } catch (error) {
            console.log("Logout Error", error);

            return error;
        }
    },

    getUserContext: async function() {
        try {
            const response = await ApiService.get(`${process.env.VUE_APP_REQUEST_URL}/context`);

            console.log("User Context Response", response.data);

            StorageService.set("sw-access-key", response.data.customer.customFields.hs_company_manager_sales_channel_ak);

            return response.data;
        } catch (error) {
            console.log("User Context Error", error);

            StorageService.set("context-token", "");
            StorageService.set("sw-access-key", "");
            ApiService.removeHeader();
        }
    }
}

export default AuthService;
export { AuthService, AuthError };
