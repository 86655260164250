<template>
  <v-container fill-height class="d-flex justify-center" fluid>
    <v-card min-width="300" class="align-center mx-auto" style="border-right: 0" elevation="10">
      <v-img
          class="white--text align-end"
          height="auto"
          src="https://www.stollberg-catering.de/fileadmin/user_upload/stollberg_0396_1280x1280_spie%C3%9Fbraten-zum-grillen.jpg"
      >
        <v-card-title right class="headline text-uppercase" style="background-color: rgba(255, 255, 255, 0.2); color: #d49f47; font-weight: 300; justify-content: center">Stark in der Region - Stark für die Region.</v-card-title>
      </v-img>
      <v-alert v-if="error && error.status" tile type="error" class="text-center" color="red lighten-2">{{ error.message ? error.message : "Unknown issue." }}</v-alert>
      <v-card-text>
        <img src="https://www.stollberg-catering.de/typo3conf/ext/hotsushi_configuration/Resources/Public/Images/stollberg_logo.png" style="width: 100px; position: absolute; top: -20px; right: -20px; box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%) !important;">
        <v-text-field type="email" v-model="email" placeholder="Ihre E-Mail-Adresse" />
        <v-text-field type="password" v-model="password" placeholder="Ihr Passwort" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary"
               dark
               large
               bottom
               tile
               @click.stop="handleLogin()"
              class="mb-n6"
        >
          Anmelden
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    email: null,
    password: null,
  }),

  computed: {
    ...mapGetters("auth", ["error"]),
  },

  methods: {
    ...mapActions("auth", ["login", "getUserContext"]),

    handleLogin() {
      if (!this.email || !this.password) {
        return false;
      }

      this.login(
          {
            email: this.email,
            password: this.password
          }
      ).then(() => this.getUserContext())
    }
  }
}
</script>