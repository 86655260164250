import ApiService from "@/services/api.service";

// TODO: Add Exception Handler

const OrderService = {
    list: async function(requestBody) {
        try {
            const response = await ApiService.post(`${process.env.VUE_APP_REQUEST_URL}/order/line-item`, requestBody);

            console.log("Order List Response", response);
            return response.data;
        } catch (error) {
            console.log("Order List Error", error);
        }
    },
}

export default OrderService;