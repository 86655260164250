<template>
  <v-navigation-drawer v-model="appDrawer" color="#383e42" dark app>
    <!-- User -->
    <template v-slot:prepend>
      <User />
      <v-divider />
    </template>

    <!-- Navigation Menu -->
    <MainNavigation />

    <!-- Logout button -->
    <template v-slot:append>
      <v-divider />
      <v-list
          dark
          dense
      >
        <!-- Logout button -->
        <v-list-item
            link
            @click.stop="logout()"
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
import { mapActions } from "vuex";
import User from "@/components/auth/User";
import MainNavigation from "@/components/navigation/MainNavigation";

export default {
  components: {User, MainNavigation},

  data: () => ({
    appDrawer: true
  }),

  methods: {
    ...mapActions("auth", ["logout"])
  }
}
</script>

<style lang="">

</style>